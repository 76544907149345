import "./services.css";
import services from "./services.json";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import photo1 from "../images/services1.jpg";
import icon1 from "../images/Icon1.svg";
import photo2 from "../images/services2.jpg";
import icon2 from "../images/Icon2.svg";
import photo3 from "../images/services3.jpg";
import icon3 from "../images/Icon3.svg";
import photo4 from "../images/services4.jpg";
import icon4 from "../images/Icon4.svg";
import planning_design_img from "../images/services/planning_design.jpg"
import onsite_monitor_img from "../images/services/onsite_monitor.jpg"
import execution_img from "../images/services/Interior_execution_img.jpg"
import post_service_img from "../images/services/Post-service-support.jpg"
import residential_design_img from "../images/services/Residential_interior_design.png"
import office_design_img from "../images/services/Office_interior_design.png"
import commercial_design_img from "../images/services/comm_interior_design.png"
import modular_kitchen_img from "../images/services/Modular_kitchen.png"
import loose_furniture_img from "../images/services/Loose_furniture.png"
import false_cieling_img from "../images/services/False_ceiling.png"
import painting_img from "../images/services/Painting.png"
import furnishing_img from "../images/services/Furnishing.png"
import electrical_plumbing_img from "../images/services/Electricity_plumbing.png"

 


export function Services() {
  const handleChange = (e) => {
    const pClass = e.target.parentElement;
    console.log("pClass1", pClass);
    if (e.target.className === "service-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
      e.target.classList.add("selected-type");
    } else if (e.target.className === "service-type selected-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
    }
  };

  const handleChangeTwo = (e) => {
    const pClass = e.target.parentElement.parentElement;
    console.log("pClass2", pClass);
    if (e.target.parentElement.className === "service-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
      e.target.parentElement.classList.add("selected-type");
    } else if (e.target.parentElement.className === "service-type selected-type") {
      for (let i = 0; i < pClass.childNodes.length; i++) {
        pClass.childNodes[i].className = "service-type";
      }
    }
  };

  return (
    <div className="services">
      <div className="services-header">
        <h1>
          Services<p>Home / Services</p>
        </h1>
      </div>
      <div className="service-types">
        <div className="service-type">
        <h2>Residential Interior Design</h2>
        <div className="hc-img">
                <img src={residential_design_img} alt="residential"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>Office Interior Design</h2>
        <div className="sc-img">
                <img src={office_design_img} alt="office"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>Commercial Interior Design</h2>
        <div className="sc-img">
                <img src={commercial_design_img} alt="commercial"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>Modular Kitchen Design</h2>
        <div className="sc-img">
                <img src={modular_kitchen_img} alt="modular kitchen"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>Loose furniture</h2>
        <div className="sc-img">
                <img src={loose_furniture_img} alt="loose furniture"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>False Ceiling</h2>
        <div className="sc-img">
                <img src={false_cieling_img} alt="false ceiling"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>Painting</h2>
        <div className="sc-img">
                <img src={painting_img} alt="Painting"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>Furnishing</h2>
        <div className="sc-img">
                <img src={furnishing_img} alt="furnishing"></img>
              </div>
        </div>

        <div className="service-type">
        <h2>Electricity & Plumbing</h2>
        <div className="sc-img">
                <img src={electrical_plumbing_img} alt="Painting"></img>
              </div>
        </div>


        {/* {services.services.map((type, index) => {
          // return (
          //   <div className="service-type" onClick={handleChange} key={index}>
          //     <h2 onClick={handleChangeTwo}>{type.service_name}</h2>
          //     <p onClick={handleChangeTwo}>{type.service_content}</p>
          //     <Link to={`/servicesingle`}>
          //       <button>
          //         Read More{" "}
          //         <BsArrowRight style={{ marginLeft: "5px" }} color="#CDA274" />
          //       </button>
          //     </Link>
          //   </div>
          // );
        })} */}
      </div>
      <div className="howWeWork">
        <div className="how-title">
          <h1>How We Work</h1>
          <p>
            Below is our standard work-process that we follow as a brand. 
          </p>
        </div>
        <div className="how-content">
            <div className="hc-img">
                <img src={planning_design_img} alt='concept'></img>
            </div>
            <div className="hc-text">
                <div className="hc-text-anima">
                    <img src={icon1} alt="icon"></img>
                    <p>01</p>
                </div>
                <div className="hc-subtext">
                    <h2>01. Planning & Design</h2>
                    <p>Our designers come up with creative personalized ideas that capture minute details to make your Interior look aesthetically pleasing. using the latest tools and technology, will aid in better visualization of what you want.</p>
                </div>
            </div>
        </div>
            
        <div className="how-content">
            <div className="hc-text">
                <div className="hc-text-anima">
                    <img src={icon2} alt="icon"></img>
                    <p>02</p>
                </div>
                <div className="hc-subtext">
                    <h2>02. Execution</h2>
                    <p>The execution of your dream house is done by the best of craftsmen, electricians, and painters transforming your dream house into reality. From the design to the landscaping, everything is taken care of so that you can enjoy your home as you imagined it.</p>
                </div>
            </div>
            <div className="hc-img">
                <img src={execution_img} alt='concept'></img>
            </div>
        </div>
        
        <div className="how-content">
            <div className="hc-img">
                <img src={onsite_monitor_img} alt='concept'></img>
            </div>
            <div className="hc-text">
                <div className="hc-text-anima">
                    <img src={icon3} alt="icon"></img>
                    <p>03</p>
                </div>
                <div className="hc-subtext">
                    <h2>03. On-Site Monitoring</h2>
                    <p>We ensure that the project is delivered on time by monitoring its progress and pace of it under the supervision of experienced supervisors and project managers, though our workmanship is of good quality, we tighten our quality checks under the supervision of supervisors and project managers. </p>
                </div>
            </div>
        </div>
        
        <div className="how-content">
            <div className="hc-text">
                <div className="hc-text-anima">
                    <img src={icon4} alt="icon"></img>
                    <p>04</p>
                </div>
                <div className="hc-subtext">
                    <h2>04. Post Service Support</h2>
                    <p>You don't need to worry if something goes wrong with post Installation. For all works undertaken by us, we provide 100% Post Installation Support. </p>
                </div>
            </div>
            <div className="hc-img">
                <img src={post_service_img} alt='concept'></img>
            </div>
        </div>
      </div>

      <div className="vikash">
        <h1>Wanna Contact Us?</h1>
        {/* <p>It is a long established fact  will be distracted.</p> */}
        <Link><button>Contact With Us<AiOutlineArrowRight style={{ marginLeft: "5px"  }} color="#292F36" /></button></Link>
      </div>
    </div>
  );
}
